<template>
  <li class="results__item">
    <div class="results__img-wrap">
      <img v-if="item.photos && item.photos.length" :src="item.photos[0].url" class="results__img" />
      <img v-else src="@/assets/images/products.png" alt="product image" class="results__img" />
    </div>

    <div class="results__info">
      <h4 class="results__title">{{ item.product }}</h4>
      <p class="results__desc"></p>
      <router-link :to="'/admin/product/' + item.productId + '/edit'" class="link">Edit</router-link>
      <div class="results__delete" @click="openModal">
        <i class="ri-delete-bin-2-line icon-yellow"></i>
      </div>
    </div>
  </li>
</template>
<script>
import Modal from './Modal'
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    openModal() {
      const mobile = document.documentElement.clientWidth <= 700
      let modalHeight = mobile ? {height: '60%', width: '75%'} : {height: 'auto', maxHeight: 600, width: '50%'}
      this.$modal.display(
        Modal,
        {
          item: this.item
        },
        {
          name: 'DeleteProductAdminModal',
          transition: 'none',
          ...modalHeight,
          classes: 'popup',
          resizable: true
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
