<template>
  <div class="popup__container">
    <h3 class="popup__title">Are you sure you want to delete ?</h3>
    <p class="popup__item"><b>Product: </b>{{ item.product }}</p>
    <p class="popup__id"><b>Id: </b>{{ item.productId }} ?</p>
    <div class="popup__buttons">
      <div class="btn btn--bgr" @click="deleteItem(item.productId)">Confirm</div>
      <div class="btn" @click="hideModal()">Discard</div>
    </div>
    <div class="popup__close" @click="hideModal()"><i class="ri-close-line"></i></div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    hideModal() {
      this.$modal.hide('DeleteProductAdminModal')
    },

    ...mapActions({
      delProduct: 'admin/products/delProduct'
    }),
    async deleteItem(id) {
      this.delProduct(id)
      this.$modal.hide('DeleteProductAdminModal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
