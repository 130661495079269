<template>
  <section class="section section--404">
    <div class="layout"></div>
    <div class="container">
      <div class="filter">
        <div class="filter__block">
          <div class="filter__title">Category</div>
          <multiselect
            :value="category"
            :options="categories"
            track-by="id"
            label="name"
            :searchable="false"
            :show-labels="false"
            class="filter__select"
            @select="filterCategory"
          >
            <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
              <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
            </span>
          </multiselect>
        </div>

        <div v-if="subCats.length || subCat" class="filter__block">
          <div class="filter__title">SubCategory</div>
          <multiselect
            v-if="subCats.length || subCat"
            :value="subCat"
            :options="subCats"
            track-by="id"
            label="name"
            :searchable="false"
            :show-labels="false"
            class="filter__select"
            @select="filterSubCat"
          >
            <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
              <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
            </span>
          </multiselect>
        </div>

        <div v-if="subSubCats.length || subSubCat" class="filter__block">
          <div class="filter__title">Sub-subCategory</div>
          <multiselect
            v-if="subSubCats.length || subSubCat"
            :value="subSubCat"
            :options="subSubCats"
            track-by="id"
            label="name"
            :searchable="false"
            :show-labels="false"
            class="filter__select"
            @select="filterSubSubCat"
          >
            <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
              <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
            </span>
          </multiselect>
        </div>

        <div v-if="filterParams.length || filterParam" class="filter__block">
          <div class="filter__title">Filters</div>
          <multiselect
            v-if="filterParams.length || filterParam"
            :value="filterParam"
            :options="filterParams"
            track-by="id"
            label="name"
            :searchable="false"
            :show-labels="false"
            class="filter__select"
            @select="changeFilterParam"
          >
            <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
              <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
            </span>
          </multiselect>
        </div>
        <div v-if="category != null" class="btn-wrap">
          <div class="btn btn--small" @click="reset">Reset Filters</div>
        </div>
      </div>
      <ul class="results">
        <Product v-for="item in products" :key="item.id" :item="item" class="results__item" />
      </ul>
      <div v-if="isLoading" class="loader">
        <loader
          object="#ffffff"
          color1="#ffffff"
          color2="#ffffff"
          size="5"
          speed="1"
          bg="#636669"
          objectbg="#FFE248"
          opacity="100"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
    </div>
  </section>
</template>

<script>
import Product from './Product'
import {mapState, mapActions, mapMutations} from 'vuex'
import Multiselect from 'vue-multiselect'
import _ from 'lodash'

export default {
  name: 'AdminProducts',
  components: {Product, Multiselect},
  data() {
    return {
      category: null,
      subCats: [],
      subCat: null,
      subSubCat: null,
      subSubCats: [],
      filterParams: [],
      filterParam: null,
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      categories: s => s.categories.categories
    }),
    ...mapState({
      products: s => s.admin.products.filteredProducts,
      searchName: s => s.admin.products.searchName,
      stateFilterCat: s => s.admin.products.filterCat,
      stateFilterSubCat: s => s.admin.products.filterSubCat,
      stateFilterSubSubCat: s => s.admin.products.filterSubSubCat,
      stateFilterParam: s => s.admin.products.filterParam
    })
  },
  // watch: {
  //   categories(cats) {
  //     this.category = cats.find(c => c.name === 'Filler Metals')
  //     this.subCats = this.category.items
  //   }
  // },
  watch: {
    searchName(name) {
      if (name) {
        this.category = this.subCat = this.subSubCat = this.filterParam = null
        this.subCats = this.subSubCats = this.filterParams = []
      }
    }
  },
  async created() {
    window.scrollTo(0, 0)
    if (this.stateFilterCat) {
      this.category = this.categories.find(c => c.name === this.stateFilterCat)
      this.subCats = this.category.items
    } else {
      // this.setFilterCat('Filler Metals')
      // if (this.categories.length) {
      //   this.category = this.categories.find(c => c.name === 'Filler Metals')
      //   this.subCats = this.category.items
      // }
    }
    if (this.stateFilterSubCat) {
      this.subCat = this.category.items.find(c => c.name === this.stateFilterSubCat)
      this.subSubCats = this.subCat.items
    }
    if (this.stateFilterSubSubCat) {
      this.subSubCat = this.subCat.items.find(c => c.name === this.stateFilterSubSubCat)
      this.filterParams = this.subSubCat.items
    }
    if (this.stateFilterParam) {
      this.filterParam = this.subSubCat.items.find(c => c.name === this.stateFilterParam)
    }
    try {
      await this.fetch()
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      fetch: 'admin/products/fetchAllProducts'
    }),
    ...mapMutations({
      setFilterCat: 'admin/products/setFilterCat',
      setFilterSubCat: 'admin/products/setFilterSubCat',
      setFilterSubSubCat: 'admin/products/setFilterSubSubCat',
      setFilterParam: 'admin/products/setFilterParam',
      filterProducts: 'admin/products/filterProducts',
      setSearchName: 'admin/products/setSearchName'
    }),
    async filterCategory(cat) {
      this.category = cat
      this.setFilterCat(cat.name)
      this.setFilterSubCat(null)
      this.setFilterSubSubCat(null)
      this.setFilterParam(null)
      this.setSearchName(null)
      this.subCat = this.subSubCat = this.filterParam = null
      this.subSubCats = this.filterParams = []
      this.subCats = cat.items
      this.filterProducts()
    },
    async filterSubCat(cat) {
      this.subCat = cat
      this.setFilterSubCat(cat.name)
      this.setFilterSubSubCat(null)
      this.setFilterParam(null)
      this.setSearchName(null)
      this.subSubCat = this.filterParam = null
      this.filterParams = []
      this.subSubCats = cat.items
      this.filterProducts()
    },
    async filterSubSubCat(cat) {
      this.subSubCat = cat
      this.setFilterSubSubCat(cat.name)
      this.setFilterParam(null)
      this.setSearchName(null)
      this.filterParam = null
      this.filterParams = cat.items
      this.filterProducts()
    },
    async changeFilterParam(param) {
      this.filterParam = param
      this.setFilterParam(param.name)
      this.filterProducts()
    },
    async reset() {
      this.category = this.subCat = this.subSubCat = this.filterParam = null
      this.subCats = this.subSubCats = this.filterParams = []
      this.setFilterCat(null)
      this.setFilterSubCat(null)
      this.setFilterSubSubCat(null)
      this.setFilterParam(null)
      this.filterProducts()
    }
  },
  metaInfo: {
    title: 'Products | Flesherton Welding Supply',
    meta: [
      {
        name: 'description',
        content: 'We offer the full range of ESAB products and maintain a high level of stock of key welding supplies.'
      }
    ]
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import 'style';
</style>
